import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  CLASSROOM_LIST_SEARCH: 'CLASSROOM:LIST_SEARCH',
  CLASSROOM_CREATE: 'CLASSROOM:CREATE',
  CLASSROOM_UPDATE: 'CLASSROOM:UPDATE',
  CLASSROOM_DELETE: 'CLASSROOM:DELETE',
  CLASSROOM_GET_BY_ID: 'CLASSROOM:GET_BY_ID',
  CLASSROOM_GET_SELECT_OPTIONS: 'CLASSROOM:GET_SELECT_OPTIONS',
  CLASSROOM_GET_BUILDINGS_BY_CAMPUSES: 'CLASSROOM:GET_BUILDINGS_BY_CAMPUSES',
  CLASSROOM_CREATE_EXCEL_BULK: 'CLASSROOM:CREATE_EXCEL_BULK',
  CLASSROOM_IMPORT_LIST_SEARCH: 'CLASSROOM:IMPORT_LIST_SEARCH',
  CLASSROOM_IMPORT_GET_SELECT_OPTIONS: 'CLASSROOM:IMPORT_GET_SELECT_OPTIONS',
  CLASSROOM_IMPORT_GET_BUILDINGS_BY_CAMPUSES: 'CLASSROOM:GET_BUILDINGS_BY_CAMPUSES',
  CLASSROOM_IMPORT_CREATE: 'CLASSROOM:IMPORT_CREATE',
  CLASSROOM_CHANGE_ALL_DATA: 'CLASSROOM:CHANGE_ALL_DATA',
};

export const ClassroomSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [],
  buildings: [],
  classroom_features: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  select_all: false,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ClassroomHoursSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [1],
  buildings: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const ClassroomFormInitialValues: Types.IClassroomItem = {
  classroom_id: undefined,
  classroom_code: '',
  status: 1,
  name: '',
  description: '',
  building_name: '',
  campus_name: '',
  building_floor: '',
  door_order: undefined,
  exam_capacity: undefined,
  lecture_capacity: undefined,
  invigilator_count: undefined,
  classroom_type: '',
  features: []
};

export const ChangeAlldataModalInitialValues: Types.IFilterChangeAllDataModal = {
  status: 0
};

export const ClassroomFeatureOptions = (T: Translator) => [
  { label: T.t('gen_smartboard'), value: 'AT' },
  { label: T.t('gen_computer'), value: 'Bil' },
  { label: T.t('gen_dvd'), value: 'DVD' },
  { label: T.t('gen_internet_access'), value: 'Int' },
  { label: T.t('gen_air_conditioner'), value: 'Klima' },
  { label: T.t('gen_projector'), value: 'Proj' },
  { label: T.t('gen_fixed_curtain'), value: 'SbtPer' },
  { label: T.t('gen_sound_system'), value: 'SesSist' },
  { label: T.t('gen_video'), value: 'Vid' }
];

export const ClassroomCategoryOptions = (T: Translator) => [
  { label: T.t('gen_accommodation'), value: 'Konaklama' },
  { label: T.t('gen_auxiliary'), value: 'Destek Birimi' },
  { label: T.t('gen_circulation'), value: 'Geçiş Yolu' },
  { label: T.t('gen_conference_hall'), value: 'Konferans Salonu' },
  { label: T.t('gen_gastronomy'), value: 'Gastronomi' },
  { label: T.t('gen_general_sanitary'), value: 'Genel Hijyen Amaçlı' },
  { label: T.t('gen_laboratory'), value: 'Laboratuvar' },
  { label: T.t('gen_lecture_hall'), value: 'Derslik' },
  { label: T.t('gen_office_space'), value: 'Ofis Alanı' },
  { label: T.t('gen_other'), value: 'Diğer' },
  { label: T.t('gen_residential'), value: 'Konut' },
  { label: T.t('gen_sanitary'), value: 'Hijyenik Amaçlı' },
  { label: T.t('gen_shared_facilities'), value: 'Ortak Tesisler' },
  { label: T.t('gen_social_infrastructure'), value: 'Sosyal Altyapı' },
  { label: T.t('gen_special_infrastructure'), value: 'Özel Altyapı' },
  { label: T.t('gen_sports'), value: 'Spor' },
  { label: T.t('gen_technical_infrastructure'), value: 'Teknik Altyapı' }
];

export const ClassroomSeatingArrangementOptions = (T: Translator) => [
  { label: T.t('gen_fixed_seating'), value: 'Sabit Sıralı Yerleşim' },
  { label: T.t('gen_armchair_seating'), value: 'Kolçaklı Sandalye Yerleşim' },
  { label: T.t('gen_sequential_seating'), value: 'Sıralı Yerleşim' },
  { label: T.t('gen_round_type'), value: 'Yuvarlak Tip' },
  { label: T.t('gen_oval_type'), value: 'Oval Tip' },
  { label: T.t('gen_u_type'), value: 'U Tipi' },
  { label: T.t('gen_l_type'), value: 'L Tipi' },
  { label: T.t('gen_cluster_type'), value: 'Küme Tipi' },
  { label: T.t('gen_amphitheater_type'), value: 'Amfi Tipi' }
];

export const ClassroomSeatingTypeOptions = (T: Translator) => [
  { label: T.t('gen_fixed_desk'), value: 'Sabit Sıra' },
  { label: T.t('gen_chair'), value: 'Sandalye' },
  { label: T.t('gen_movable_desk'), value: 'Hareketli Sıra' },
  { label: T.t('gen_table'), value: 'Masa' }
];

export const UserTypeOptions = (T: Translator) => [
  { label: T.t('gen_academic_staff'), value: '1' },
  { label: T.t('gen_admin_staff'), value: '2' },
  { label: T.t('gen_other'), value: '4' },
  { label: T.t('gen_public'), value: '5' },
  { label: T.t('gen_staff'), value: '6' },
  { label: T.t('gen_students'), value: '3' },
  { label: T.t('gen_teaching_activitity'), value: '5' },
  { label: T.t('gen_technician'), value: '8' },
  { label: T.t('gen_third_party'), value: '7' }
];

export const SpecialUsingStateOptions = (T: Translator) => [
  { label: T.t('gen_exhibition_area'), value: 'Sergi Alanı' },
  { label: T.t("gen_female"), value: "Kadın" },
  { label: T.t("gen_male"), value: "Erkek" },
  { label: T.t("gen_unisex"), value: "Uniseks" },
  { label: T.t("gen_cleaning_staff"), value: "Temizlik Personeli" },
  { label: T.t("gen_mailboxes"), value: "Posta Kutusu" },
  { label: T.t("gen_outdoor_terrace"), value: "Açık Teras" },
  { label: T.t("gen_recycling"), value: "Geri Dönüşüm" },
  { label: T.t("gen_waste_disposal"), value: "Atık Bertarafı" },
  { label: T.t("gen_server_room"), value: "Sunucu Odası" },
  { label: T.t("gen_rehearsal_room"), value: "Prova Odası" }
]


export const ClassroomStatusOptions = (T: Translator) => [
  { label: T.t('gen_in_use'), value: "Kullanimda" },
  { label: T.t('gen_potentially_unused'), value: "Potansiyel olarak kullanilmayan" },
  { label: T.t('gen_incomplete'), value: "Tamamlanmamis" },
  { label: T.t('gen_not_specified'), value: "Belirtilmemis" },
  { label: T.t('gen_unused'), value: "Kullanilmayan" }
]

export const ClassroomAttributeOptions = (T: Translator) => [
  { label: T.t('gen_large'), value: 'Büyük' },
  { label: T.t('gen_public'), value: 'Kamu' },
  { label: T.t('gen_small'), value: 'Küçük' },
  { label: T.t('gen_medium'), value: 'Orta' },
  { label: T.t('gen_private'), value: 'Özel' }
];

export const ClassroomUsageStateOptions = (T: Translator) => [
  { label: T.t('gen_in_use'), value: 'Kullanımda' },
  { label: T.t('gen_potentially_unused'), value: 'Potansiyel olarak kullanılmayan' },
  { label: T.t('gen_incomplete'), value: 'Tamamlanmamış' },
  { label: T.t('gen_not_specified'), value: 'Belirtilmemiş' },
  { label: T.t('gen_unused'), value: 'Kullanılmayan' }
];

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_building_code_and_classroom_code'), value: 'BUILDINGCODE_CLASSROOMCODE' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];