import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import {
  ClassroomSearchInitialValues,
  ClassroomFeatureOptions,
  ClassroomCategoryOptions,
  ClassroomAttributeOptions,
  ClassroomUsageStateOptions,
  ClassroomSeatingTypeOptions,
  ClassroomSeatingArrangementOptions,
  UserTypeOptions,
  SpecialUsingStateOptions
} from '../../store/constants/classroom-const';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import ClassroomForm from './classroom-form';
import ClassroomImportForm from './classroom-import-form';
import SyncClassroomModal from './classroom-sync-modal';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import DownloadButton from '../../components/excel-imports/export';
import DownloadButtonSyncResult from '../../components/excel-imports/export-sync-result';
import { SectionTypes } from '../../store/constants/enums';
import ChangeAllDataModal from './change-all-data-modal';
import Translator from '../../services/translate-factory';
import { Slider } from 'antd';
import { ValueType } from 'react-select/lib/types';

const T = Translator.create();
const L = Log.create('FacultyListPage');

class ClassroomTable extends Component<Types.IClassroomPageProps, Types.IClassroomPageState> {
  state: Types.IClassroomPageState = {
    filters: Object.assign({}, ClassroomSearchInitialValues),
    filterIsOpen: false,
    classroomFormIsOpen: false,
    classroomImportFormIsOpen: false,
    integrationModalIsOpen: false,
    classroomId: undefined,
    all_ids: [],
    selected_ids: [],
    selected_classrooms: [],
    changeAllDataModalIsOpen: false
  };

  constructor(props: any) {
    super(props)
    this.toggleFilter = this.toggleFilter.bind(this)
    this.onFilterClassroom = this.onFilterClassroom.bind(this)
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    if (window.location.pathname.startsWith("/static/") && this.props.term_id != -1) {
      this.props.dispatch(Actions.SetTermInfo(-1, -1));
    }
    this.init();
  }

  componentDidUpdate() {
    if (window.location.pathname.startsWith("/static/") && this.props.term_id != -1) {
      this.props.dispatch(Actions.SetTermInfo(-1, -1));
      this.props.dispatch(Actions.Notification('gen_selected_term_removed', 'gen_warning', 'warning'))
      this.init();
    }
  }

  init() {
    this.state = {
      filters: Object.assign({}, ClassroomSearchInitialValues),
      filterIsOpen: false,
      classroomFormIsOpen: false,
      classroomImportFormIsOpen: false,
      integrationModalIsOpen: false,
      classroomId: undefined,
      all_ids: [],
      selected_ids: [],
      selected_classrooms: [],
      changeAllDataModalIsOpen: false
    };
    this.state.filters.page = 1;
    this.searchClassrooms();
    this.getClassroomSelectOptions();
    this.getBuildingsByCampusesAtClassrooms([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchClassrooms() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.classroom.CLASSROOM_LIST_SEARCH, this.state.filters, 'classroom-list-spin')
    );
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  getBuildingsByCampusesAtClassrooms = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES, campusIds, 'classroom-list-spin'));
  }

  onClassroomImported = () => {
    this.searchClassrooms();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchClassrooms();
  };

  classroomFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(window.location.pathname, '/create')));
  };

  classroomImportFormIsOpen = () => {
    this.setState({
      ...this.state,
      classroomImportFormIsOpen: true
    });
  };

  classroomFormOnClose = (refresh: boolean) => {
    let path = window.location.pathname.startsWith('/static/') ? GT.Route(Routes.STATIC_CLASSROOM) : GT.Route(Routes.CLASSROOM)
    if (this.state.classroomFormIsOpen) {
      this.props.dispatch(Actions.Navigation(path));
    }
    if (refresh) {
      this.searchClassrooms();
      this.getClassroomSelectOptions();
      this.getBuildingsByCampusesAtClassrooms([]);
    }
  };

  classroomImportFormOnClose = (refresh: boolean) => {
    this.setState({
      ...this.state,
      classroomImportFormIsOpen: false
    });
    if (refresh) {
      this.searchClassrooms();
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ClassroomSearchInitialValues);
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFilterClassroom(model: Types.IFilterClassroom, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...model,
        page: 1,
      },
      filterIsOpen: true,
    }));
    this.searchClassrooms();
    FormActions.setSubmitting(false);
  }

  onClassroomEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(window.location.pathname + '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.classroom.CLASSROOM_LIST_SEARCH,
            this.state.filters,
            'classroom-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids,
                selected_classrooms: [],
                selected_total_exam_capacity: 0,
                selected_total_lecture_capacity: 0,
                selected_id: undefined
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteClassroom = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_classroom_question'),
          name: 'classroom_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = ClassroomSearchInitialValues;
                this.setState(this.state);
                this.searchClassrooms();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.classroom.CLASSROOM_DELETE,
                deleteList,
                'classroom-list-spin',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onSelectClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList,
        selected_id: id
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  onChangeAllData = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  }

  switchChangeAlldataModalStatus = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  };


  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.IClassroomPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.classroomFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.classroomId = props.match.params.id;
      } else {
        state.classroomId = undefined;
      }
    } else {
      hasNewState = true;
      state.classroomFormIsOpen = false;
      state.classroomId = undefined;
    }

    if (state.selected_id) {
      state.selected_classroom = props.results!.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      let isAlreadyOnSelectedClassroom = state.selected_classrooms!.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      if (isAlreadyOnSelectedClassroom.length != 0) {
        let index = state.selected_classrooms!.findIndex((item: any) => isAlreadyOnSelectedClassroom.map((item: any) => item.classroom_id).includes(Number(item.classroom_id)));
        state.selected_classrooms!.splice(index, 1);
      } else {
        state.selected_classrooms = state.selected_classroom != undefined && state.selected_classroom != null ? state.selected_classrooms!.concat(state.selected_classroom) : state.selected_classrooms;
      }
      state.selected_total_exam_capacity = state.selected_classrooms!.map((classroom: any) => classroom.exam_capacity).reduce((a, b) => a + b, 0);
      state.selected_total_lecture_capacity = state.selected_classrooms!.map((classroom: any) => classroom.lecture_capacity).reduce((a, b) => a + b, 0);
      state.selected_id = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let classroomList = this.props.results;
    let classroomFeatureOptions = this.getClassroomFeatureOptions();

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t('gen_classrooms')}</h4>
              <Spinner name="classroom-list-spin" />
              <div className="white-container mt-4">
                {this.renderFilter()}
                {this.renderTable(classroomList, classroomFeatureOptions)}
              </div>
            </div>
          </div>
        </SimplePage>
        {this.renderModals()}
      </MainLayout>
    );
  }

  getClassroomFeatureOptions() {
    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [
        ...ClassroomFeatureOptions(T),
        ...this.props.selectOptions.additional_classroom_features
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomFeatureOptions;
  }

  getClassroomCategoryOptions() {
    let classroomCategoryOptions = ClassroomCategoryOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_categories && this.props.selectOptions.additional_classroom_categories.length > 0) {
      classroomCategoryOptions = [
        ...ClassroomCategoryOptions(T),
        ...this.props.selectOptions.additional_classroom_categories
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomCategoryOptions;
  }

  getClassroomAttributeOptions() {
    let classroomAttributeOptions = ClassroomAttributeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_attributes && this.props.selectOptions.additional_classroom_attributes.length > 0) {
      classroomAttributeOptions = [
        ...ClassroomAttributeOptions(T),
        ...this.props.selectOptions.additional_classroom_attributes
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomAttributeOptions;
  }

  getClassroomUsageStateOptions() {
    let classroomUsageStateOptions = ClassroomUsageStateOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_usage_states && this.props.selectOptions.additional_classroom_usage_states.length > 0) {
      classroomUsageStateOptions = [
        ...ClassroomUsageStateOptions(T),
        ...this.props.selectOptions.additional_classroom_usage_states
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomUsageStateOptions;
  }

  getClassroomSeatingTypeOptions() {
    let classroomSeatingTypeOptions = ClassroomSeatingTypeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_seating_types && this.props.selectOptions.additional_classroom_seating_types.length > 0) {
      classroomSeatingTypeOptions = [
        ...ClassroomSeatingTypeOptions(T),
        ...this.props.selectOptions.additional_classroom_seating_types
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomSeatingTypeOptions;
  }

  getClassroomSeatingArrangementOptions() {
    let classroomSeatingArrangementOptions = ClassroomSeatingArrangementOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_seating_arrangements && this.props.selectOptions.additional_classroom_seating_arrangements.length > 0) {
      classroomSeatingArrangementOptions = [
        ...ClassroomSeatingArrangementOptions(T),
        ...this.props.selectOptions.additional_classroom_seating_arrangements
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomSeatingArrangementOptions;
  }

  getUserTypesOptions() {
    let userTypeOptions = UserTypeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.aditional_user_types && this.props.selectOptions.aditional_user_types.length > 0) {
      userTypeOptions = [
        ...UserTypeOptions(T),
        ...this.props.selectOptions.aditional_user_types
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return userTypeOptions;
  }

  getSpecialUsingStateOptions() {
    let specialUsingStates = SpecialUsingStateOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.aditional_special_using_states && this.props.selectOptions.aditional_special_using_states.length > 0) {
      specialUsingStates = [
        ...SpecialUsingStateOptions(T),
        ...this.props.selectOptions.aditional_special_using_states
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return specialUsingStates;
  }


  renderButtons() {
    if (this.props.user && this.props.user.role === 's') {
      if (this.state.selected_ids && this.state.selected_ids.length) {
        return (
          <>
            <button
              id='button_cancel_selection'
              className="category-tag-square tag-gray float-left pr-2 pl-2"
              style={{ margin: '5px' }}
              onClick={this.handleCancelSelection}
            >
              <i className="material-icons mr-2">close</i>
              <span>{T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b></span>
            </button>
            <button
              id='button_delete_classroom'
              className="category-tag-square float-left pr-2 pl-2"
              style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
              onClick={this.onDeleteClassroom}
            >
              <i className="material-icons mr-2">delete_outline</i>
              <span> {T.t('gen_delete_selected')}</span>
            </button>
            {!this.state.filters.select_all ? (
              <>
                <span className="category-tag-square tag-info float-left pr-2 pl-2" style={{ margin: '5px' }}>
                  {T.t('gen_total_exam_capacity')} <b>&nbsp;({this.state.selected_total_exam_capacity})</b>
                </span>
                <span className="category-tag-square tag-info float-left pr-2 pl-2" style={{ margin: '5px' }}>
                  {T.t('gen_total_lecture_capacity')} <b>&nbsp;({this.state.selected_total_lecture_capacity})</b>
                </span>
              </>
            ) : null}
            <button
              id='button_sync'
              className="category-tag-square float-left pr-2 pl-2 tag-green"
              style={{ margin: '5px', color: '#fff' }}
              onClick={this.onChangeAllData}
            >
              <i className="material-icons mr-2">sync</i>
              <span> {T.t('gen_change_all_data')}</span>
            </button>
          </>
        );
      } else {
        return (
          <>
            <button
              id='button_add'
              className="category-tag-square tag-green"
              onClick={this.props.term_id === -1 ? this.classroomFormIsOpen : this.classroomImportFormIsOpen}
            >
              <i className="material-icons mr-2">add</i>
              {T.t('gen_add_classroom')}
            </button>
            {this.props.term_id === -1 && this.renderAdditionalButtons()}
            <DownloadButton title={T.t('gen_classrooms')} sectionType={SectionTypes.Classrooms} />
          </>
        );
      }
    }
    return null;
  }

  renderAdditionalButtons() {
    return (
      <>
        <button
          id='button_list_sync'
          className="category-tag-square tag-green ml-1"
          onClick={this.switchIntegrationModalStatus}
        >
          <i className="material-icons mr-2">playlist_add</i>
          {T.t('gen_list_sync_jobs')}
        </button>
        <ImportModal
          title={T.t('gen_add_with_excel')}
          componentKey={ExcelImportKeys.Classrooms}
          dispatch={this.props.dispatch}
          onImport={this.onClassroomImported}
        />
        <DownloadButtonSyncResult title={'SyncClassroomsResult'} sectionType={SectionTypes.SyncClassroomsResult} />
      </>
    );
  }

  renderFilter() {
    const classroomFeatureOptions = this.getClassroomFeatureOptions();
    return (
      <>
        <div className="row align-items-center mb-1">
          <div className="col-md-10 col-sm-4 col-12">
            {this.renderButtons()}
          </div>
          <div className="col-md-2 col-sm-8 col-12 text-right">
            <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
              <button
                id='button_filter'
                className="category-tag-square tag-glass float-right ml-3 mr-3"
                style={{ margin: '5px' }}
                onClick={this.toggleFilter}
              >
                <i className="material-icons mr-2">filter_list</i>
                <span>{T.t('gen_filter')}</span>
              </button>
            </div>
          </div>
        </div>
        <div className={`white-container mt-4 collapse ${this.state.filterIsOpen ? `show` : ``}`} id="advance-search">
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={ClassroomSearchInitialValues}
              enableReinitialize={true}
              onSubmit={this.onFilterClassroom}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterClassroom>) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h6>{T.t('gen_filter_parameters')}</h6>
                    </div>
                    {this.renderFilterInputs(props, classroomFeatureOptions)}
                  </div>
                  {this.renderFilterFormFooter(props)}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </>
    );
  }

  setOptionValues(
    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>,
    props: FormikProps<Types.IFilterClassroom>,
    setList: string,
    setValues: string,
  ) {
    const list: Array<Types.ISelectOption> = options
      ? (options as Array<Types.ISelectOption>)
      : [];
    props.setFieldValue(setList, list);
    props.setFieldValue(
      setValues,
      list.map((item) => item.value)
    );
  }

  renderFilterInputs(props: FormikProps<Types.IFilterClassroom>, classroomFeatureOptions: Types.ISelectOption[]) {
    let classroomCategoryOptions = this.getClassroomCategoryOptions();
    let classroomAttributeOptions = this.getClassroomAttributeOptions();
    let classroomUsageStateOptions = this.getClassroomUsageStateOptions();
    let classroomSeatingTypeOptions = this.getClassroomSeatingTypeOptions();
    let classroomSeatingArrangementOptions = this.getClassroomSeatingArrangementOptions();
    let userTypeOptions = this.getUserTypesOptions();
    let specialUsingStateOptions = this.getSpecialUsingStateOptions();

    return (
      <>
        <div className="col-md-3 mt-3">
          {this.renderInputField("classroom_code", props.values.classroom_code, props.handleChange, T.t('gen_classroom_code'), "filter_1")}
        </div>
        <div className="col-md-3 mt-3">
          {this.renderInputField("name", props.values.name, props.handleChange, T.t('gen_classroom_name'), "title")}
        </div>
        <div className="col-md-3">
          {this.renderSelectField(
            'select_campus',
            T.t('gen_campus'),
            this.props.selectOptions && this.props.selectOptions.campuses || [],
            props.values.campuses,
            (options) => {
              const list = options ? options as Types.ISelectOption[] : [];
              props.setFieldValue('campuses', list);
              props.setFieldValue('campus_ids', list.map(item => item.value));
              this.getBuildingsByCampusesAtClassrooms(list.map(item => item.value));
            },
            T.t('gen_select_campus')
          )}
        </div>
        <div className="col-md-3">
          {this.renderSelectField(
            'select_building',
            T.t('gen_building'),
            props.values.campus_ids && props.values.campus_ids.length > 0 ?
              (this.props.buildings_related_campuses || []) :
              (this.props.selectOptions && this.props.selectOptions.buildings || []),
            props.values.buildings,
            (options) => {
              this.setOptionValues(options, props, 'buildings', 'building_ids')
            },
            T.t('gen_select_building')
          )}
        </div>
        <div className="col-md-4">
          {this.renderSelectField(
            'select_type',
            T.t('gen_type_function'),
            this.props.selectOptions && this.props.selectOptions.classroom_types || [],
            props.values.classroom_types,
            (options) => {
              this.setOptionValues(options, props, 'classroom_types', 'classroom_type_ids')
            },
            T.t('gen_select_type_function')
          )}
        </div>

        {this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active ? (
          <React.Fragment>
            <div className="col-md-4">
              {this.renderSelectField(
                'category',
                T.t('gen_category'),
                classroomCategoryOptions,
                props.values.classroom_categories,
                (options) => {
                  this.setOptionValues(options, props, 'classroom_categories', 'classroom_category_ids')
                },
                T.t('gen_select_category')
              )}
            </div>
            <div className="col-md-4">
              {this.renderSelectField(
                'attributes',
                T.t('gen_attribute'),
                classroomAttributeOptions,
                props.values.classroom_attributes,
                (options) => {
                  this.setOptionValues(options, props, 'classroom_attributes', 'classroom_attribute_ids')
                },
                T.t('gen_choose_attribute')
              )}
              {props.errors && props.errors.classroom_attributes && props.submitCount > 0 && (
                <div className="error">{props.errors.classroom_attributes}</div>
              )}
            </div>
            <div className="col-md-4">
              {this.renderSelectField(
                'seating_arrangements',
                T.t('gen_seating_arrangement'),
                classroomSeatingArrangementOptions,
                props.values.classroom_seating_arrangements,
                (options) => {
                  this.setOptionValues(options, props, 'classroom_seating_arrangements', 'classroom_seating_arrangement_ids')
                },
                T.t('gen_choose_seating_arrangement')
              )}
              {props.errors && props.errors.classroom_seating_arrangements && props.submitCount > 0 && (
                <div className="error">{props.errors.classroom_seating_arrangements}</div>
              )}
            </div>
            <div className="col-md-4">
              {this.renderSelectField(
                'seating_types',
                T.t('gen_seating_type'),
                classroomSeatingTypeOptions,
                props.values.classroom_seating_types,
                (options) => {
                  this.setOptionValues(options, props, 'classroom_seating_types', 'classroom_seating_type_ids')
                },
                T.t('gen_choose_seating_type')
              )}
              {props.errors && props.errors.classroom_seating_types && props.submitCount > 0 && (
                <div className="error">{props.errors.classroom_seating_types}</div>
              )}
            </div>
            <div className="col-md-4">
              {this.renderSelectField(
                'select_feature',
                T.t('gen_classroom_features'),
                classroomFeatureOptions,
                props.values.classroom_features,
                (options) => {
                  this.setOptionValues(options, props, 'classroom_features', 'classroom_feature_codes')
                },
                T.t('gen_select_feature')
              )}
            </div>
            <div className="col-md-4">
              {this.renderSelectField(
                'classroom_usage_states',
                T.t('gen_using_state'),
                classroomUsageStateOptions,
                props.values.classroom_usage_states,
                (options) => {
                  this.setOptionValues(options, props, 'classroom_usage_states', 'classroom_usage_state_ids')
                },
                T.t('gen_choose_using_state')
              )}
              {props.errors && props.errors.classroom_usage_states && props.submitCount > 0 && (
                <div className="error">{props.errors.classroom_usage_states}</div>
              )}
            </div>
            <div className="col-md-4">
              {this.renderSelectField(
                'user_type_states',
                T.t('gen_user_type'),
                userTypeOptions,
                props.values.user_type_states,
                (options) => {
                  this.setOptionValues(options, props, 'user_type_states', 'user_type_state_ids')
                },
                T.t('gen_choose_user_type')
              )}
              {props.errors && props.errors.user_type_states && props.submitCount > 0 && (
                <div className="error">{props.errors.user_type_states}</div>
              )}
            </div>
            <div className="col-md-4">
              {this.renderSelectField(
                'user_type_states',
                T.t('gen_special_using_state'),
                specialUsingStateOptions,
                props.values.special_using_state_states,
                (options) => {
                  this.setOptionValues(options, props, 'special_using_state_states', 'special_using_state_state_ids')
                },
                T.t('gen_choose_special_using_state')
              )}
              {props.errors && props.errors.special_using_state_states && props.submitCount > 0 && (
                <div className="error">{props.errors.special_using_state_states}</div>
              )}
            </div>
          </React.Fragment>
        ) : null}

        <div className="col-md-2 mt-3">
          {this.renderNumberInputField("building_floor", props.values.building_floor, props.handleChange, T.t('gen_floor'))}
        </div>
        <div className="col-md-2 mt-3">
          {this.renderNumberInputField("door_order", props.values.door_order, props.handleChange, T.t('gen_door_number'))}
        </div>
        {this.props.term_type !== 0 && (
          <div className="col-md-2 mt-3">
            {this.renderNumberInputField("exam_capacity", props.values.exam_capacity, props.handleChange, T.t('gen_exam_capacity'))}
          </div>
        )}
        <div className="col-md-2 mt-3">
          {this.renderNumberInputField("lecture_capacity", props.values.lecture_capacity, props.handleChange, T.t('gen_lecture_capacity'))}
        </div>

        {this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active ? (
          <React.Fragment>
            <div className="col-md-4">
              {this.renderRangeInputField("area", props.values.area_min, props.values.area_max, props.handleChange, T.t('gen_meter_square_area'), "m²", (values: number[]) => {
                props.setFieldValue('area_min', values[0])
                props.setFieldValue('area_max', values[1])
              })}
            </div>
            <div className="col-md-4">
              {this.renderRangeInputField("volume", props.values.volume_min, props.values.volume_max, props.handleChange, T.t('gen_meter_cubic_area'), "m³",
                (values: number[]) => {
                  props.setFieldValue('volume_min', values[0])
                  props.setFieldValue('volume_max', values[1])
                })}
            </div>
          </React.Fragment>
        ) : null}
      </>
    );
  }

  renderNumberInputField(id: string, value: any, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, label: string) {
    return (
      <div className="form-input form-group date-picker">
        <input
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          type="number"
          required
        />
        <span className="highlight" />
        <span className="bar" />
        <label htmlFor={id}>{label}</label>
      </div>
    );
  }

  renderInputField(id: string, value: any, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, label: string, icon: string) {
    return (
      <div className="form-input form-group date-picker">
        <input
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          type="text"
          required
        />
        <span className="highlight" />
        <span className="bar" />
        <label htmlFor={id}>{label}</label>
        <i className="material-icons">{icon}</i>
      </div>
    );
  }

  renderSelectField(id: string, label: string, options: Types.ISelectOption[], value: any, onChange: (options: any) => void, placeholder: string) {
    return (
      <div className="add-custom-tag mb-3">
        <div className="react-select-container">
          <label>{label}</label>
          <Select
            id={id}
            className="react-select"
            isMulti={true}
            filterOption={(option, query) =>
              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
            }
            closeMenuOnSelect={false}
            options={options}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            noOptionsMessage={() => T.t('gen_no_options_available')}
          />
        </div>
      </div>
    );
  }
  renderRangeInputField(id: string, minValue: any, maxValue: any, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, label: string, unit: string, setSlider: (values: number[]) => void) {
    return (
      <div className='react-select-container tw-p-6 tw-bg-gray-100 tw-rounded-lg'>
        <label>{label} ({unit})</label>
        <div className='tw-flex tw-flex-row tw-items-center tw-justify-between'>
          <div className="form-input form-group date-picker">
            <input
              id={`${id}_min`}
              name={`${id}_min`}
              value={minValue}
              onChange={onChange}
              type="number"
              required
            />
            <span className="highlight" />
            <span className="bar" />
            <label htmlFor={`${id}_min`}>{T.t('gen_minimum')}</label>
          </div>
          {" - "}
          <div className="form-input form-group date-picker">
            <input
              id={`${id}_max`}
              name={`${id}_max`}
              value={maxValue}
              onChange={onChange}
              type="number"
              required
            />
            <span className="highlight" />
            <span className="bar" />
            <label htmlFor={`${id}_max`}>{T.t('gen_maximum')}</label>
          </div>
        </div>
        <Slider
          marks={{
            0: `0${unit}`,
            200: `200${unit}`
          }}
          range
          min={0}
          max={200}
          value={[minValue || -1, maxValue || -1]}
          tooltip={{
            formatter(value) {
              return `${value}${unit}`
            },
          }}
          onChange={setSlider}
        />
      </div>
    );
  }

  renderFilterFormFooter(props: FormikProps<Types.IFilterClassroom>) {
    return (
      <>
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="text-left">
              <h6>{T.t('gen_status')}</h6>
              <div className="tick-radio position-relative mb-3 d-inline-block">
                <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
              </div>
              <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row mt-3">
          <div className="col-6">
            <button
              id='button_arrow_upward'
              type="button"
              onClick={() => {
                this.setState({ filterIsOpen: false });
              }}
              className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
            >
              <i className="material-icons">arrow_upward</i>
            </button>
            <button
              id='button_delete_sweep'
              type="reset"
              onClick={props.handleReset}
              className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
            >
              <i className="material-icons">delete_sweep</i>
            </button>
          </div>
          <div className="col-6 text-right">
            <button
              id='button_search'
              type="button"
              className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
              onClick={() => props.handleSubmit()}
              disabled={props.isSubmitting}
            >
              <i className="material-icons mr-2">search</i> {T.t('gen_search')}
            </button>
          </div>
        </div>
      </>
    );
  }


  toggleFilter() {
    this.setState(prev => ({
      ...prev,
      filterIsOpen: !prev.filterIsOpen
    }))
  }

  handleCancelSelection() {
    this.setState(prev => ({
      selected_ids: [],
      selected_classrooms: [],
      selected_total_exam_capacity: 0,
      selected_total_lecture_capacity: 0,
      selected_id: undefined,
      filters: {
        ...prev.filters,
        select_all: false
      }
    }));
  }

  renderTable(classroomList: Types.IClassroomItem[] | undefined,
    classroomFeatureOptions: { label: any; value: string; }[]) {
    return (
      <div className="row">
        <div className="col-12">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
            <thead>
              {this.renderTableHeader()}
            </thead>
            <tbody>
              {classroomList && classroomList.length ? (
                classroomList.map(item => this.renderTableRow(item, classroomFeatureOptions))
              ) : null}
            </tbody>
          </table>
          {this.renderPagination()}
        </div>
      </div>
    );
  }

  renderTableHeader() {
    return (
      <tr>
        {this.props.user && this.props.user.role === 's' ? (
          <th data-cell="select">
            <div className="tick-radio position-relative">
              <input
                id='select_all'
                type="checkbox"
                className="form-radio"
                checked={this.checkAllIdsSelected()}
                onChange={this.onSelectAll}
              />
            </div>
          </th>
        ) : null}
        <SortedColumn datacell="status" title={T.t('gen_status')} sortkey="status" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        <SortedColumn datacell="classroom_code" title={T.t('gen_code')} sortkey="classroom_code" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        <SortedColumn datacell="name" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_name')} sortkey="name" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        <SortedColumn datacell="building_name" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_campus_and_building')} sortkey="building_name" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        <SortedColumn datacell="building_floor" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_floor')} sortkey="building_floor" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        <SortedColumn datacell="door_order" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_door_number')} sortkey="door_order" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        {this.props.term_type !== 0 ? (
          <SortedColumn datacell="exam_capacity" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_exam_capacity')} sortkey="exam_capacity" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        ) : null}
        <SortedColumn datacell="lecture_capacity" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_lecture_capacity')} sortkey="lecture_capacity" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        {this.props.term_type !== 0 ? (
          <SortedColumn datacell="invigilator_count" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_invigilator_count')} sortkey="invigilator_count" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        ) : null}
        <SortedColumn datacell="classroom_type" className="d-none d-lg-table-cell d-xl-table-cell text-center" title={T.t('gen_type')} sortkey="classroom_type" sortedcolumn={this.state.filters.order_by} sort={this.sort} />
        <th scope="col" className="text-center">{T.t('gen_features')}</th>
        <th scope="col" className="text-center">{T.t('gen_description')}</th>
        {this.props.user && this.props.user.role === 's' ? (
          <th scope="col" className="text-right">
            <span className="text-right">{T.t('gen_actions')}</span>
          </th>
        ) : null}
      </tr>
    );
  }

  getClassroomFeature(item: Types.IClassroomItem, classroomFeatureOptions: {
    label: any;
    value: string;
  }[]) {
    let classroomFeatures: {
      label: any;
      value: string;
    }[] = [];

    if (item.feature_codes && item.feature_codes.length) {
      item.feature_codes.forEach(featureCode => {
        let feature = classroomFeatureOptions.find(opt => opt.value === featureCode) || GT.GetUnknownSelectOption(featureCode);
        classroomFeatures.push(feature);
      });
    }
    return classroomFeatures
  }
  renderTableRow(item: Types.IClassroomItem,
    classroomFeatureOptions: { label: any; value: string; }[]
  ) {
    const classroomFeatures = this.getClassroomFeature(item, classroomFeatureOptions)

    return (
      <tr key={'classroom-' + item.classroom_id} data-title={item.name}>
        {this.props.user && this.props.user.role === 's' ? (
          <td data-cell="select">
            <div className="tick-radio position-relative">
              <input
                id='select_classroom'
                type="checkbox"
                className="form-radio"
                checked={item.classroom_id && this.state.selected_ids && this.state.selected_ids.includes(item.classroom_id) || false}
                data-id={item.classroom_id}
                onChange={this.onSelectClassroom}
              />
            </div>
          </td>
        ) : null}
        <td scope="row" data-label={T.t('gen_status')}>
          <div className="tags ml-1 mr-4">
            <button
              id='button_status'
              className={`small-tag text-uppercase${item.status == 1 ? ' tag-green' : ' tag-red'}`}
            >
              {GT.GetActiveStatus(item.status)}
            </button>
          </div>
        </td>
        <td scope="row" data-label={T.t('gen_code')}>{item.classroom_code}</td>
        <td data-label={T.t('gen_name')} className="text-center">{item.name}</td>
        <td data-label={T.t('gen_campus_and_building')} className="text-center">{item.building_name}</td>
        <td data-label={T.t('gen_floor')} className="text-center">{item.building_floor}</td>
        <td data-label={T.t('gen_door_number')} className="text-center">{item.door_order}</td>
        {this.props.term_type !== 0 ? (
          <td data-label={T.t('gen_exam_capacity')} className="text-center">{item.exam_capacity}</td>
        ) : null}
        <td data-label={T.t('gen_lecture_capacity')} className="text-center">{item.lecture_capacity}</td>
        {this.props.term_type !== 0 ? (
          <td data-label={T.t('gen_invigilator_count')} className="text-center">{item.invigilator_count}</td>
        ) : null}
        <td data-label={T.t('gen_type')} className="text-center">{item.classroom_type}</td>
        <td className="text-center">
          {classroomFeatures.length > 0 ? (
            <div className="table-scrollable-td">
              {classroomFeatures.map((i, index) => (
                <span>{i.label}{index < classroomFeatures.length - 1 ? ', ' : ''}<br /></span>
              ))}
            </div>
          ) : '-'}
        </td>
        <td data-label={T.t('gen_description')} className="text-center">
          {item.description ? (
            <div className="table-scrollable-td">{item.description}</div>
          ) : '-'}
        </td>
        {this.props.user && this.props.user.role === 's' ? (
          <td data-label={T.t('gen_actions')} className="table-buttons">
            <div className="table-buttons-wrapper">
              <button
                id='button_edit'
                data-toggle="tooltip"
                data-id={item.classroom_id}
                onClick={this.onClassroomEdit}
                title={T.t('gen_edit')}
                className="btn btn-light btn-sm table-button"
              >
                <span className="d-block" data-toggle="modal" data-target="#addNew">
                  <i className="material-icons">edit</i>
                </span>
              </button>
            </div>
          </td>
        ) : null}
      </tr>
    );
  }

  renderPagination() {
    return (
      <div className="row-options justify-content-end">
        <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
          {this.props.results && this.props.results.length > 0 && (
            <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
          )}
        </div>
      </div>
    );
  }

  renderModals() {
    return (
      <>
        <ClassroomForm
          classroomId={this.state.classroomId}
          formIsOpen={this.state.classroomFormIsOpen}
          onClose={this.classroomFormOnClose}
        />
        <ClassroomImportForm
          formIsOpen={this.state.classroomImportFormIsOpen != null ? this.state.classroomImportFormIsOpen : false}
          onClose={this.classroomImportFormOnClose}
        />
        {this.state.integrationModalIsOpen && (
          <SyncClassroomModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={this.searchClassrooms}
          />
        )}
        <ChangeAllDataModal
          modalIsOpen={this.state.changeAllDataModalIsOpen}
          onClose={this.switchChangeAlldataModalStatus}
          term_id={this.state.filters.term_id}
          selected_ids={this.state.selected_ids}
        />
      </>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomPageProps
): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    user: store.state.user,
    results: store.state.classroom_page && store.state.classroom_page.results,
    filters: store.state.classroom_page && store.state.classroom_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    term_type: store.state.term_type,
    buildings_related_campuses: store.state.select_options && store.state.select_options.buildings_related_campuses,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_page) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page.results,
        next.state.classroom_page && next.state.classroom_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildings_related_campuses,
        next.state.select_options && next.state.select_options.buildings_related_campuses
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomTable);

export default container;
