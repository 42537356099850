import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomFormInitialValues,
  ClassroomFeatureOptions,
  ClassroomCategoryOptions,
  ClassroomAttributeOptions,
  ClassroomUsageStateOptions,
  ClassroomSeatingArrangementOptions,
  ClassroomSeatingTypeOptions,
  UserTypeOptions,
  SpecialUsingStateOptions
} from '../../store/constants/classroom-const';
import * as Types from '../../store/types';
import { ExamClassroomFormValidation } from './validations/classroom-form-val';
import { CourseClassroomFormValidation } from './validations/course-classroom-form-val';
import Translator from '../../services/translate-factory';
import ImageDropzone from '../../components/upload/ImageDropzone';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';

const T = Translator.create();
const Logger = Log.create('ClassroomForm');

function getInitialState(): Types.IClassroomFormState {
  const initialValues: Types.IClassroomFormState = {
    model: Object.assign({}, ClassroomFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class ClassroomForm extends Component<Types.IClassroomFormProps, Types.IClassroomFormState> {
  state: Types.IClassroomFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  static getDerivedStateFromProps(props: Types.IClassroomFormProps, state: Types.IClassroomFormState) {
    let hasNewState: boolean = false;
    if (props.classroomId && props.classroomId != state.model.classroom_id) {
      state.model.classroom_id = props.classroomId;
      props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BY_ID, props.classroomId, 'classroom-form-spin')
      );
      hasNewState = true;
    }
    if (props.form && props.classroomId && props.classroomId == state.model.classroom_id) {
      state = getInitialState();
      state.model = Object.assign(state.model, props.form);
      if (props.form.feature_codes) {
        let classroomFeatureOptions = ClassroomFeatureOptions(T);
        if (props.selectOptions && props.selectOptions.additional_classroom_features && props.selectOptions.additional_classroom_features.length > 0) {
          classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
        }

        state.model.feature_codes = props.form.feature_codes;
        let classroomFeatures: any = [];
        props.form.feature_codes.map((featureCode: string) => {
          let feature = classroomFeatureOptions.find((item: Types.ISelectOption) => item.value == featureCode);
          classroomFeatures.push(feature);
        });
        state.model.features = classroomFeatures;
      }
      if (props.form.building) {
        state.model.building_id = props.form.building.value;
      }
      if (props.form.classroomTypeSelect) {
        state.model.classroom_type = props.form.classroomTypeSelect.value;
      }
      if (props.form && props.form.classroom_category) {
        let classroom_category_option = ClassroomCategoryOptions(T).find((item: Types.ISelectOption) => item.value == props.form!.classroom_category);
        if (classroom_category_option == undefined && props.selectOptions) {
          classroom_category_option = props.selectOptions.additional_classroom_categories.find((item: Types.ISelectOption) => item.value == props.form!.classroom_category);
        }
        state.model.classroom_category_options = classroom_category_option != undefined ? [classroom_category_option] : [];
      } else {
        state.model.classroom_category_options = [];
      }
      if (props.form && props.form.classroom_attribute) {
        let classroom_attribute_option = ClassroomAttributeOptions(T).find((item: Types.ISelectOption) => item.value == props.form!.classroom_attribute);
        if (classroom_attribute_option == undefined && props.selectOptions) {
          classroom_attribute_option = props.selectOptions.additional_classroom_attributes.find((item: Types.ISelectOption) => item.value == props.form!.classroom_attribute);
        }
        state.model.classroom_attribute_options = classroom_attribute_option != undefined ? [classroom_attribute_option] : [];
      } else {
        state.model.classroom_attribute_options = [];
      }
      if (props.form && props.form.classroom_usage_state) {
        let classroom_usage_state_option = ClassroomUsageStateOptions(T).find((item: Types.ISelectOption) => item.value == props.form!.classroom_usage_state);
        if (classroom_usage_state_option == undefined && props.selectOptions) {
          classroom_usage_state_option = props.selectOptions.additional_classroom_usage_states.find((item: Types.ISelectOption) => item.value == props.form!.classroom_usage_state);
        }
        state.model.classroom_usage_state_options = classroom_usage_state_option != undefined ? [classroom_usage_state_option] : [];
      } else {
        state.model.classroom_usage_state_options = [];
      }
      if (props.form && props.form.classroom_seating_type) {
        let classroom_seating_type_option = ClassroomSeatingTypeOptions(T).find((item: Types.ISelectOption) => item.value == props.form!.classroom_seating_type);
        if (classroom_seating_type_option == undefined && props.selectOptions) {
          classroom_seating_type_option = props.selectOptions.additional_classroom_seating_types.find((item: Types.ISelectOption) => item.value == props.form!.classroom_seating_type);
        }
        state.model.classroom_seating_type_options = classroom_seating_type_option != undefined ? [classroom_seating_type_option] : [];
      } else {
        state.model.classroom_seating_type_options = [];
      }
      if (props.form && props.form.classroom_seating_arrangement) {
        let classroom_seating_arrangement_option = ClassroomSeatingArrangementOptions(T).find((item: Types.ISelectOption) => item.value == props.form!.classroom_seating_arrangement);
        if (classroom_seating_arrangement_option == undefined && props.selectOptions) {
          classroom_seating_arrangement_option = props.selectOptions.additional_classroom_seating_arrangements.find((item: Types.ISelectOption) => item.value == props.form!.classroom_seating_arrangement);
        }
        state.model.classroom_seating_arrangement_options = classroom_seating_arrangement_option != undefined ? [classroom_seating_arrangement_option] : [];
      } else {
        state.model.classroom_seating_arrangement_options = [];
      }
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.classroomId && state.model.classroom_id) {
      return getInitialState();
    } else return null;
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IClassroomItem, FormActions: FormikActions<Types.IClassroomItem>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };

    if (this.props.classroomId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_UPDATE, model, 'classroom-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_CREATE, model, 'classroom-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };


  getClassroomFeatureOptions() {
    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }
    return classroomFeatureOptions
  }

  getClassroomCategoryOptions() {
    let classroomCategoryOptions = ClassroomCategoryOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_categories && this.props.selectOptions.additional_classroom_categories.length > 0) {
      classroomCategoryOptions = [
        ...ClassroomCategoryOptions(T),
        ...this.props.selectOptions.additional_classroom_categories
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomCategoryOptions;
  }

  getClassroomAttributeOptions() {
    let classroomAttributeOptions = ClassroomAttributeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_attributes && this.props.selectOptions.additional_classroom_attributes.length > 0) {
      classroomAttributeOptions = [
        ...ClassroomAttributeOptions(T),
        ...this.props.selectOptions.additional_classroom_attributes
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomAttributeOptions;
  }

  getClassroomUsageStateOptions() {
    let classroomUsageStateOptions = ClassroomUsageStateOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_usage_states && this.props.selectOptions.additional_classroom_usage_states.length > 0) {
      classroomUsageStateOptions = [
        ...ClassroomUsageStateOptions(T),
        ...this.props.selectOptions.additional_classroom_usage_states
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomUsageStateOptions;
  }

  getClassroomSeatingTypeOptions() {
    let classroomSeatingTypeOptions = ClassroomSeatingTypeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_seating_types && this.props.selectOptions.additional_classroom_seating_types.length > 0) {
      classroomSeatingTypeOptions = [
        ...ClassroomSeatingTypeOptions(T),
        ...this.props.selectOptions.additional_classroom_seating_types
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomSeatingTypeOptions;
  }

  getUserTypesOptions() {
    let userTypeOptions = UserTypeOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.aditional_user_types && this.props.selectOptions.aditional_user_types.length > 0) {
      userTypeOptions = [
        ...UserTypeOptions(T),
        ...this.props.selectOptions.aditional_user_types
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return userTypeOptions;
  }

  getSpecialUsingStateOptions() {
    let specialUsingStates = SpecialUsingStateOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.aditional_special_using_states && this.props.selectOptions.aditional_special_using_states.length > 0) {
      specialUsingStates = [
        ...SpecialUsingStateOptions(T),
        ...this.props.selectOptions.aditional_special_using_states
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return specialUsingStates;
  }

  getClassroomSeatingArrangementOptions() {
    let classroomSeatingArrangementOptions = ClassroomSeatingArrangementOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_seating_arrangements && this.props.selectOptions.additional_classroom_seating_arrangements.length > 0) {
      classroomSeatingArrangementOptions = [
        ...ClassroomSeatingArrangementOptions(T),
        ...this.props.selectOptions.additional_classroom_seating_arrangements
      ].sort((a, b) => (a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1));
    }
    return classroomSeatingArrangementOptions;
  }

  sortType(a: Types.ISelectOption, b: Types.ISelectOption) {
    return a.label.toLowerCase() >= b.label.toLowerCase() ? 1 : -1
  }

  getValidation() {
    return this.props.term_type !== 0 ? ExamClassroomFormValidation(T) : CourseClassroomFormValidation(T);
  }

  getModalTitle() {
    return this.props.classroomId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_classroom')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_classroom')}</>
  }

  setOptionValues(
    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>,
    props: FormikProps<Types.IClassroomItem>,
    setList: string,
    setValues: string,
  ) {
    const list: Array<Types.ISelectOption> = options
      ? (options as Array<Types.ISelectOption>)
      : [];
    props.setFieldValue(setList, list);
    props.setFieldValue(
      setValues,
      list.map((item) => item.value)
    );
  }

  setOptionValuesConditional(
    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>,
    props: FormikProps<Types.IClassroomItem>,
    setList: string,
    setValues: string,
  ) {
    const list: Array<Types.ISelectOption> = options
      ? (options as Array<Types.ISelectOption>)
      : [];
    if (props.values.user_capacity && list.length <= props.values.user_capacity) {
      this.setOptionValues(options, props, setList, setValues)
    }
  }

  renderCategory({ props }: { props: FormikProps<Types.IClassroomItem> }) {
    let classroomCategoryOptions = this.getClassroomCategoryOptions();

    return this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active ? (
      <div className="col-md-5">
        <div className="add-custom-tag mb-3">
          <div className="react-select-container">
            <label>{T.t('gen_category')}</label>
            <Select
              id='classroom_type'
              className="react-select"
              isMulti={false}
              filterOption={(option: any, query: any) =>
                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
              }
              closeMenuOnSelect={true}
              options={classroomCategoryOptions}
              placeholder={T.t('gen_select_category')}
              value={props.values.classroom_category_options}
              onChange={(option: any) => {
                props.setFieldValue('classroom_category_options', [option]);
                props.setFieldValue('classroom_category', option && option.value);
              }}
              noOptionsMessage={(): string => T.t('gen_no_options_available')}
            />
          </div>
          {props.errors && props.errors.classroom_type && props.submitCount > 0 && (
            <div className="error">{props.errors && props.errors.classroom_type}</div>
          )}
        </div>
      </div>
    ) : <div className="col-md-5" />
  }

  renderEventModuleFields({ props }: { props: FormikProps<Types.IClassroomItem> }) {
    let classroomAttributeOptions = this.getClassroomAttributeOptions();
    let classroomUsageStateOptions = this.getClassroomUsageStateOptions();
    let classroomSeatingTypeOptions = this.getClassroomSeatingTypeOptions();
    let classroomSeatingArrangementOptions = this.getClassroomSeatingArrangementOptions();
    let userTypeOptions = this.getUserTypesOptions();
    let specialUsingStateOptions = this.getSpecialUsingStateOptions();

    return this.props.general_settings && this.props.general_settings.event_module_status === SettingsEventModuleStatus.Active ? (
      <React.Fragment>
        <div className="col-md-5">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label>{T.t("gen_attribute")}</label>
              <Select
                className="react-select"
                name="attribute"
                id="attribute"
                isMulti={false}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={true}
                options={classroomAttributeOptions}
                placeholder={T.t('gen_enter_attribute')}
                value={props.values.classroom_attribute_options}
                onChange={(option: any) => {
                  props.setFieldValue('classroom_attribute_options', [option]);
                  props.setFieldValue('classroom_attribute', option && option.value);
                }}
                noOptionsMessage={(): string => T.t('gen_no_options_available')}
              />
            </div>
            {props.errors && props.errors.classroom_attribute && props.submitCount > 0 && (
              <div className="error">{props.errors && props.errors.classroom_attribute}</div>
            )}
          </div>
        </div>
        <div className="col-md-7">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label>{T.t("gen_seating_arrangement")}</label>
              <Select
                className="react-select"
                name="seating_arrangements"
                id="seating_arrangements"
                isMulti={false}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={true}
                options={classroomSeatingArrangementOptions}
                placeholder={T.t('gen_choose_seating_arrangement')}
                value={props.values.classroom_seating_arrangement_options}
                onChange={(option: any) => {
                  props.setFieldValue('classroom_seating_arrangement_options', [option]);
                  props.setFieldValue('classroom_seating_arrangement', option && option.value);
                }}
                noOptionsMessage={(): string => T.t('gen_no_options_available')}
              />
            </div>
            {props.errors && props.errors.classroom_seating_arrangement && props.submitCount > 0 && (
              <div className="error">{props.errors && props.errors.classroom_seating_arrangement}</div>
            )}
          </div>
        </div>
        <div className="col-md-5">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label>{T.t("gen_seating_type")}</label>
              <Select
                className="react-select"
                name="seating_type"
                id="seating_type"
                isMulti={false}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={true}
                options={classroomSeatingTypeOptions}
                placeholder={T.t('gen_enter_seating_type')}
                value={props.values.classroom_seating_type_options}
                onChange={(option: any) => {
                  props.setFieldValue('classroom_seating_type_options', [option]);
                  props.setFieldValue('classroom_seating_type', option && option.value);
                }}
                noOptionsMessage={(): string => T.t('gen_no_options_available')}
              />
            </div>
            {props.errors && props.errors.classroom_seating_type && props.submitCount > 0 && (
              <div className="error">{props.errors && props.errors.classroom_seating_type}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label>{T.t("gen_user_type")}</label>
              <Select
                className="react-select"
                name="user_type"
                id="user_type"
                isMulti={false}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={true}
                options={userTypeOptions}
                placeholder={T.t('gen_enter_user_type')}
                value={props.values.user_type_options}
                onChange={(option: any) => {
                  props.setFieldValue('user_type_options', [option]);
                  props.setFieldValue('user_type', option && option.value);
                }}
                noOptionsMessage={(): string => T.t('gen_no_options_available')}
              />
            </div>
            {props.errors && props.errors.user_type && props.submitCount > 0 && (
              <div className="error">{props.errors && props.errors.user_type}</div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label>{T.t("gen_special_using_state")}</label>
              <Select
                className="react-select"
                name="special_using_state"
                id="special_using_state"
                isMulti={false}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={true}
                options={specialUsingStateOptions}
                placeholder={T.t('gen_enter_special_using_state')}
                value={props.values.special_using_state_options}
                onChange={(option: any) => {
                  props.setFieldValue('special_using_state_options', [option]);
                  props.setFieldValue('special_using_state', option && option.value);
                }}
                noOptionsMessage={(): string => T.t('gen_no_options_available')}
              />
            </div>
            {props.errors && props.errors.special_using_state && props.submitCount > 0 && (
              <div className="error">{props.errors && props.errors.special_using_state}</div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label>{T.t("gen_using_state")}</label>
              <Select
                className="react-select"
                name="classroom_usage_state"
                id="classroom_usage_state"
                isMulti={false}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={true}
                options={classroomUsageStateOptions}
                placeholder={T.t('gen_choose_using_state')}
                value={props.values.classroom_usage_state_options}
                onChange={(option: any) => {
                  props.setFieldValue('classroom_usage_state_options', [option]);
                  props.setFieldValue('classroom_usage_state', option && option.value);
                }}
                noOptionsMessage={(): string => T.t('gen_no_options_available')}
              />
            </div>
            {props.errors && props.errors.classroom_usage_state && props.submitCount > 0 && (
              <div className="error">{props.errors && props.errors.classroom_usage_state}</div>
            )}
          </div>
        </div>
        <div className="col-md-6 form-input form-group with-icon">
          <input
            id="user_capacity"
            name="user_capacity"
            value={props.values.user_capacity}
            onChange={(e) => {
              if (props.values.classroom_users && parseInt(e.target.value) < props.values.classroom_users.length) {
                props.values.classroom_users = [];
              }
              props.handleChange(e);
            }}
            onBlur={props.handleBlur}
            type="number"
            defaultValue={'0'}
            min={0}
            max={5}
            onKeyDown={(event) => { event.preventDefault(); }}
          />
          <span className="highlight" />
          <span className="bar" />
          <label htmlFor="user_capacity">{T.t('gen_classroom_user_capacity')}</label>
        </div>
        <div className="col-md-6">
          <div className="add-custom-tag mb-3">
            <div className="react-select-container">
              <label>{T.t('gen_classroom_users')}</label>
              <Select
                id='feature'
                className="react-select"
                isMulti={true}
                isDisabled={!(props.values.user_capacity && props.values.user_capacity > 0)}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                closeMenuOnSelect={false}
                options={this.props.selectOptions && this.props.selectOptions.available_users}
                placeholder={T.t('gen_select_user')}
                value={props.values.classroom_users}
                onChange={(options) => this.setOptionValuesConditional(options, props, 'classroom_users', 'classroom_user_ids')}
                noOptionsMessage={(): string => T.t('gen_select_no_user')}
              />
              <span className="highlight" />
              <span className="bar" />
            </div>
          </div>
        </div>
        <div className="col-md-6 form-input form-group with-icon">
          <input
            id="area"
            name="area"
            value={props.values.area}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="number"
            min={0}
          />
          <span className="highlight" />
          <span className="bar" />
          <label htmlFor="area">{T.t('gen_meter_square_area')}</label>
          {props.errors && props.errors.area && props.submitCount > 0 && (
            <div className="error">{props.errors && props.errors.area}</div>
          )}
        </div>
        <div className="col-md-6 form-input form-group with-icon">
          <input
            id="volume"
            name="volume"
            value={props.values.volume}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="number"
            min={0}
          />
          <span className="highlight" />
          <span className="bar" />
          <label htmlFor="volume">{T.t('gen_meter_cubic_area')}</label>
          {props.errors && props.errors.volume && props.submitCount > 0 && (
            <div className="error">{props.errors && props.errors.volume}</div>
          )}
        </div>
      </React.Fragment>
    ) : <div className="col-md-5" />
  }

  render() {
    let classroomFeatureOptions = this.getClassroomFeatureOptions()

    const validation = this.getValidation()
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />

        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={validation}
        >
          {(props: FormikProps<Types.IClassroomItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit, setFieldValue } = props;
            if (values.area != undefined && this.state.model.area == undefined) {
              this.state.model.area = 0;
            }
            if (values.volume != undefined && this.state.model.volume == undefined) {
              this.state.model.volume = 0;
            }
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          {this.getModalTitle()}
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="classroom_code"
                              name="classroom_code"
                              value={values.classroom_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="classroom_code">{T.t('gen_code')}</label>
                            {errors && errors.classroom_code && props.submitCount > 0 && (
                              <div className="error">{errors && errors.classroom_code}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.name && props.submitCount > 0 && (
                              <div className="error">{errors && errors.name}</div>
                            )}
                          </div>
                          <div className="col-md-7">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_campus_and_building')}</label>
                                <Select
                                  id='select_building'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={this.props.selectOptions && this.props.selectOptions.buildings}
                                  placeholder={T.t('gen_select_building')}
                                  value={props.values.buildingSelect}
                                  onChange={(option: any) => {
                                    props.setFieldValue('building_id', option.value);
                                    props.setFieldValue('buildingSelect', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                />
                              </div>
                              {errors && errors.building_id && props.submitCount > 0 && (
                                <div className="error">{errors && errors.building_id}</div>
                              )}
                            </div>
                          </div>

                          {this.renderCategory({ props })}

                          <div className="col-md-7">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_type_function')}</label>
                                <Select
                                  id='classroom_type'
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={true}
                                  options={this.props.selectOptions && this.props.selectOptions.classroom_types_active}
                                  placeholder={T.t('gen_select_type_function')}
                                  value={props.values.classroomTypeSelect}
                                  onChange={(option: any) => {
                                    props.setFieldValue('classroom_type', option.value);
                                    props.setFieldValue('classroomTypeSelect', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                              {errors && errors.classroom_type && props.submitCount > 0 && (
                                <div className="error">{errors && errors.classroom_type}</div>
                              )}
                            </div>
                          </div>

                          {this.renderEventModuleFields({ props })}

                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="building_floor"
                              name="building_floor"
                              value={values.building_floor}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="building_floor">{T.t('gen_floor')}</label>
                            {errors && errors.building_floor && props.submitCount > 0 && (
                              <div className="error">{errors && errors.building_floor}</div>
                            )}
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="door_order"
                              name="door_order"
                              value={values.door_order}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="door_order">{T.t('gen_door_number')}</label>
                            {errors && errors.door_order && props.submitCount > 0 && (
                              <div className="error">{errors && errors.door_order}</div>
                            )}
                          </div>
                          {this.props.term_type !== 0 ? (
                            <div className="col-md-4 form-input form-group with-icon">
                              <input
                                id="exam_capacity"
                                name="exam_capacity"
                                value={values.exam_capacity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"

                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_capacity">{T.t('gen_exam_capacity')}</label>
                              {errors && errors.exam_capacity && props.submitCount > 0 && (
                                <div className="error">{errors && errors.exam_capacity}</div>
                              )}
                            </div>
                          ) : null}
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="lecture_capacity"
                              name="lecture_capacity"
                              value={values.lecture_capacity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"

                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="lecture_capacity">{T.t('gen_lecture_capacity')}</label>
                            {errors && errors.lecture_capacity && props.submitCount > 0 && (
                              <div className="error">{errors && errors.lecture_capacity}</div>
                            )}
                          </div>
                          {this.props.term_type !== 0 ? (
                            <div className="col-md-4 form-input form-group with-icon">
                              <input
                                id="invigilator_count"
                                name="invigilator_count"
                                value={values.invigilator_count}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"

                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_count">{T.t('gen_invigilator_count')}</label>
                              {errors && errors.invigilator_count && props.submitCount > 0 && (
                                <div className="error">{errors && errors.invigilator_count}</div>
                              )}
                            </div>
                          ) : null}
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_features')}</label>
                                <Select
                                  id='feature'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={classroomFeatureOptions}
                                  placeholder={T.t('gen_select_feature')}
                                  value={props.values.features}
                                  onChange={(options) => this.setOptionValues(options, props, 'features', 'feature_codes')}
                                  noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            {errors && errors.description && props.submitCount > 0 && (
                              <div className="error">{errors && errors.description}</div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          this.props.term_id === -1 &&
                          <div className="row mt-3">
                            <div className="col-md-12">
                              <ImageDropzone
                                uploadAreaText={T.t('gen_you_can_import_images_here')}
                                title={T.t('gen_images')}
                                type="default"
                                imageList={values.photos}
                                setImages={(newImages) => setFieldValue("photos", newImages)}
                              />
                            </div>
                          </div>
                        }
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomFormProps
): Types.IClassroomFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IClassroomFormProps = Object.assign({}, ownProps, {
    form:
      store.state.classroom_page && store.state.classroom_page.form
        ? store.state.classroom_page.form
        : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_page) {
    return (
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page.form,
        next.state.classroom_page && next.state.classroom_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomForm);

export default container;
